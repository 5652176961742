$(document).ready(function(){
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('[data-toggle="tooltip"]').tooltip();

    /* Delete Modal */
    $('.remove-record').click(function() {
        var id = $(this).attr('data-id');
        var url = $(this).attr('data-url');
        $(".remove-record-model").attr("action",url);
        $('body').find('.remove-record-model').append('<input name="id" type="hidden" value="'+ id +'">');
    });

    $('.remove-data-from-delete-form').click(function() {
        $('body').find('.remove-record-model').find('input[name="id"]').remove();
    });
    /* Delete Modal */

    /* Confirm modal */
    var confirm_modal_target;
    $(".confirm-btn").click(function(){
        $("#confirm-modal").modal('show');
        confirm_modal_target = $(this).data('target');

        if($(this).data('message') != ""){
            $("#confirmModalMessage").html($(this).data('message'));
        }
    });

    $("#modal-btn-yes").on("click", function(){
        $("#confirm-modal").modal('hide');
        $("#"+confirm_modal_target).submit();
    });

    $("#modal-btn-no").on("click", function(){
        $("#confirm-modal").modal('hide');
    });
    /* Confirm modal */
});
