$(document).on('click', '#detailButton', function(event) {
    event.preventDefault();
    let href = $(this).attr('data-attr');
    $.ajax({
        url: href,
        beforeSend: function() {
            $('#dokranIndicator').show();
        },
        // return the result
        success: function(result) {
            $('#detailModal').modal("show");
            $('#detailBody').html(result).show();
        },
        complete: function() {
            $('#dokranIndicator').hide();
        },
        error: function(jqXHR, testStatus, error) {
            console.log(error);
            alert("Page " + href + " cannot open. Error:" + error);
            $('#dokranIndicator').hide();
        },
        timeout: 8000
    })
});


$(document).on('click', '#notificationDetailButton', function(event) {
    event.preventDefault();
    let href = $(this).attr('data-attr');
    $.ajax({
        url: href,
        beforeSend: function() {
            $('#dokranIndicator').show();
        },
        // return the result
        success: function(result) {
            $('#notificationDetailModal').modal("show");
            $('#notificationDetailBody').html(result).show();
        },
        complete: function() {
            $('#dokranIndicator').hide();
        },
        error: function(jqXHR, testStatus, error) {
            console.log(error);
            alert("Page " + href + " cannot open. Error:" + error);
            $('#dokranIndicator').hide();
        },
        timeout: 8000
    })
});

$('body').on('focus',".timepicker", function(){
    $(this).datetimepicker({
        format: 'HH:mm',
        icons: {
            time: "bi bi-clock",
            date: "bi bi-calendar",
            up: "bi bi-arrow-up",
            down: "bi bi-arrow-down",
            previous: "bi bi-chevron-left",
            next: "bi bi-chevron-right",
            today: "bi bi-clock-o",
            clear: "bi bi-trash-o"
        }
    });
});
//Template-index
$(document).on('click', '#templateCreateButton', function(event) {
    event.preventDefault();
    let href = $(this).attr('data-attr');
    $.ajax({
        url: href,
        beforeSend: function() {
            window.indicator.show();
        },
        // return the result
        success: function(result) {
            $('#formModal').modal("show");
            $('#formBody').html(result).show();
        },
        complete: function() {
            window.indicator.hide();
        },
        error: function(jqXHR, testStatus, error) {
            console.log(error);
            alert("Page " + href + " cannot open. Error:" + error);
            window.indicator.hide();
        },
        timeout: 8000
    })
});
//balances-add
$(document).on('paste', '#card_no', function(e) {
    e.preventDefault();

    var withoutSpaces = e.originalEvent.clipboardData.getData('Text');
    withoutSpaces = withoutSpaces.replace(/\s+/g, '');
    $(this).val(withoutSpaces);
});
